import React from 'react';
import { shape } from 'prop-types';
import Section from '../section';
import FiscalData from '../../pages/home/fiscal-data';
import LazyComponent from '../lazy-component';
import ErrorBoundaryComponent from '../error-boundary/component';

const RegulationRow = ({
  componentProps,
  childrenProps,
}) => {
  const { deviceType, siteId } = childrenProps;
  const { components, type } = componentProps;
  const componentsList = components;
  const namespace = 'container';
  return (
    <Section type={type}>
      <div className={deviceType !== 'mobile' ? namespace : null}>
        <div className={`${namespace}-${type}`}>
          {componentsList.map((component, i) => (
            <ErrorBoundaryComponent key={component.id || component.type} componentType={component.type}>
              <LazyComponent
                lazyLoad={false}
                {...component}
                {...childrenProps}
                elementOrder={i}
              />
            </ErrorBoundaryComponent>
          ))}
          <FiscalData siteId={siteId} deviceType={deviceType} />
        </div>
      </div>
    </Section>
  );
};

RegulationRow.propTypes = {
  componentProps: shape({}),
  childrenProps: shape({}),
};

export default RegulationRow;
