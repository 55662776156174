import React, { createContext, useContext } from 'react';
import { node, shape, number, elementType } from 'prop-types';
import { LAZYLOAD_WHEN_VISIBLE } from '../../../../utils/constants';

const LazyComponentContext = createContext();

const defaultConfig = {
  strategy: {
    default: LAZYLOAD_WHEN_VISIBLE,
  },
  startInComponent: 4,
};

export const LazyComponentProvider = ({ strategy = null, startInComponent = null, componentLoader, children = null }) => {
  const lazyConfig = {
    startInComponent: startInComponent || defaultConfig.startInComponent,
    strategy: {
      ...defaultConfig.strategy,
      ...strategy,
    },
    componentLoader,
  };

  return (
    <LazyComponentContext.Provider value={lazyConfig}>{children}</LazyComponentContext.Provider>
  );
};

export const useLazyComponentContext = () => useContext(LazyComponentContext);

LazyComponentProvider.propTypes = {
  strategy: shape({}),
  startInComponent: number,
  componentLoader: elementType.isRequired,
  children: node,
};
