import React from 'react';
import { loadable } from 'nordic/lazy';

import Home from './home';
import { LAZYLOAD_IGNORE, LAZYLOAD_SSRONLY } from '../../../../utils/constants';
import { LazyComponentProvider } from '../../commons/lazy-component/context';

const lazyStrategy = {
  adn: LAZYLOAD_IGNORE,
  'mplay-media-card': LAZYLOAD_SSRONLY,
};

const mapAvailableComponents = [
  'adn',
  'categories-new-design',
  'collections-v2-design',
  'complaints-book',
  'discovery',
  'dynamic-access',
  'exhibitor',
  'financial-user-info',
  'help-email',
  'loyalty-benefits',
  'loyalty-partner-subscriptions',
  'mplay-media-card',
  'national-insurance-info',
  'navigation',
  'payment-data',
  'recommendations-carousel-dual',
  'recommendations-grid',
  'recommendations',
  'regret',
  'site-shopping-info',
  'thb-double',
];

const componentMap = (type) => (!mapAvailableComponents.includes(type) ? 'fallback-component' : `desktop/${type}`);
const componentLoader = (type) => loadable(() => import(`./available-components/${componentMap(type)}`));

function HomeDesktop(props) {
  return (
    <LazyComponentProvider
      strategy={lazyStrategy}
      startInComponent={4}
      componentLoader={componentLoader}
    >
      <Home {...props} />
    </LazyComponentProvider>
  );
}

export default HomeDesktop;
