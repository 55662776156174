import React from 'react';
import { string } from 'prop-types';
import Image from '../../../commons/image';
import Section from '../../../commons/section';

const AFIP = 'afip';
const IMAGE_SRC = 'data_fiscal.png';
const HREF = 'http://qr.afip.gob.ar/?qr=eqSGL6HcpJM1lYHWSGfj-Q,,';
const MOBILE = 'mobile';
function FiscalData({ deviceType = null, siteId = null }) {
  const Element = deviceType !== MOBILE ? 'div' : Section;

  return deviceType !== MOBILE && siteId === 'MLA' && (
    <Element className={AFIP}>
      <a href={HREF} target="_F960AFIPInfo" className={`${AFIP}-link`}>
        <Image lazyLoad src={IMAGE_SRC} alt={AFIP} />
      </a>
    </Element>
  );
}

FiscalData.propTypes = {
  deviceType: string,
  siteId: string,
};

export default FiscalData;

