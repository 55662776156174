/* global newrelic: false */
import { string } from 'prop-types';

function TrackNewrelic({ newrelicTransactionName, newrelicNoticeError }) {
  if (typeof newrelic === 'function' && newrelicTransactionName) {
    newrelic.setPageViewName(newrelicTransactionName);
  } else if (typeof newrelic === 'function' && newrelicNoticeError) {
    newrelic.noticeError(new Error(), newrelicNoticeError);
  }

  return null;
}

TrackNewrelic.propTypes = {
  newrelicTransactionName: string,
  newrelicNoticeError: string,
};

export default TrackNewrelic;
