import React from 'react';
import { shape, string, number } from 'prop-types';
import { LazyHydrate } from 'nordic/lazy';

import { useLazyComponentContext } from './context';
import {
  LAZYLOAD_SSRONLY,
  LAZYLOAD_WHEN_IDLE,
  LAZYLOAD_WHEN_VISIBLE,
  LAZYLOAD_IGNORE,
} from '../../../../utils/constants';

const LazyComponent = ({
  ...props
}) => {
  const { type, elementOrder, recommendation_data } = props;
  const { strategy, startInComponent, componentLoader } = useLazyComponentContext();
  const Component = componentLoader(type);

  let lazyStrategy = strategy.default;
  if (recommendation_data || type === 'recommendations-carousel-dual') {
    lazyStrategy = LAZYLOAD_WHEN_IDLE;
  } else if (strategy[type]) {
    lazyStrategy = strategy[type];
  }

  if (elementOrder < startInComponent || lazyStrategy === LAZYLOAD_IGNORE) {
    return <Component {...props} />;
  }

  return (
    <LazyHydrate
      ssrOnly={lazyStrategy === LAZYLOAD_SSRONLY}
      whenIdle={lazyStrategy === LAZYLOAD_WHEN_IDLE}
      whenVisible={lazyStrategy === LAZYLOAD_WHEN_VISIBLE}
    >
      <Component {...props} />
    </LazyHydrate>
  );
};

LazyComponent.propTypes = {
  id: string,
  type: string,
  elementOrder: number,
  recommendation_data: shape({}),
};

export default LazyComponent;
