/**
* Styles
*/
import '../../shared/components/pages/home/home.desktop.scss';

/**
* Module dependencies
*/
import startApp from '../start-app';

import Home from '../../shared/components/pages/home/home.desktop';

startApp(Home);
