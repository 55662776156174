/* eslint-env browser */

import React from 'react';

import I18n from 'nordic/i18n';
import I18nProvider from 'nordic/i18n/I18nProvider';
import { loadableReady } from 'nordic/lazy';
import { hydrateRoot } from 'nordic/hydrate';
import { ImageProvider } from 'nordic/image';
import restClient from './services/rest-client';

export default function startApp(Component) {
  const preloadedState = window.ML_PRELOADED_STATE;


  const i18n = new I18n({ translations: preloadedState.translations });
  const restClientWithToken = restClient({
    deviceType: preloadedState.deviceType,
    params: { _csrf: preloadedState.csrfToken },
  });

  loadableReady(() => {
    hydrateRoot(
      document.getElementById('root-app'),
      <ImageProvider prefix={preloadedState.imagesPrefix}>
        <I18nProvider i18n={i18n}>
          <Component {...preloadedState} restClient={restClientWithToken} />
        </I18nProvider>
      </ImageProvider>,
    );
  });

  window.newrelic?.setErrorHandler((err) => {
    const ignoreStatusMessages = [
      'Script error.',
    ];
    let response = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ignoreStatusMessages.length; i++) {
      const element = ignoreStatusMessages[i];
      if (err.message === element) response = true;
    }

    return response;
  });
}
