import restclient from 'nordic/restclient';
import merge from 'lodash/merge';
import querystring from 'querystring';

const defaultOptions = {
  baseURL: '/gz/home/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export default (options) => {
  const client = restclient(merge({}, defaultOptions, options));

  client.originalDoRequest = client.doRequest;
  client.doRequest = function doRequest(args) {
    return this.originalDoRequest(args)
      .catch((err) => {
        const res = err && err.response && err.response.data;
        if (res && res.code === 401 && res.data && res.data.login) {
          let stringLoginParams = '';
          if (args.loginParams) {
            stringLoginParams = `&${querystring.stringify(args.loginParams)}`;
          }

          window.location = `${res.data.login}${stringLoginParams}`;
          return;
        }
        throw err;
      });
  };

  return client;
};
