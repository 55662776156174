import React from 'react';
import { string, bool } from 'prop-types';
import { Image } from 'nordic/image';
import { LAZYLOAD_ON, LAZYLOAD_OFF } from '../../../../utils/constants';

const ImageCommon = ({
  className = null,
  lazyLoad = false,
  src,
  src2x = null,
  alt = null,
  size = null,
  preload = false,
  ariaHidden,
}) => (
  <Image
    src={src}
    className={className}
    alt={alt}
    srcSet={src2x ? `${src} 1x, ${src2x} 2x` : null}
    width={size}
    height={size}
    lazyload={lazyLoad ? LAZYLOAD_ON : LAZYLOAD_OFF}
    preload={preload}
    aria-hidden={ariaHidden}
  />
);

ImageCommon.propTypes = {
  src: string.isRequired,
  alt: string,
  className: string,
  lazyLoad: bool,
  src2x: string,
  size: string,
  preload: bool,
  ariaHidden: string,
};

export default ImageCommon;

