/* eslint-disable react/jsx-key */
import React from 'react';
import { array } from 'prop-types';

const Schema = ({ schemaData = null }) => {
  if (schemaData) {
    return (
      schemaData.map(schema => (
        // eslint-disable-next-line nordic/no-script-tags
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      ))
    );
  }
  return null;
};

Schema.propTypes = {
  schemaData: array,
};

export default Schema;
