import React from 'react';
import { string, array } from 'prop-types';
import { Head } from 'nordic/head';

function Header({ title, description, canonical, googleVerification = null, preloadRecommendationsImgs = null }) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:image" content="https://http2.mlstatic.com/static/org-img/homesnw/mercado-libre.png?v=2" />
      {googleVerification ? <meta name="google-site-verification" content={googleVerification} /> : null}
      <link rel="canonical" href={canonical} />
      <link rel="preconnect" href="https://adservice.google.com" />
      <link rel="preconnect" href="https://www.googletagservices.com" />
      <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
      <link rel="preconnect" href="https://tpc.googlesyndication.com" />
      <link rel="preconnect" href="https://pagead2.googlesyndication.com" />
      <link rel="preconnect" href="https://data.mercadolibre.com" />
      <link rel="alternate" hrefLang="es-AR" href="https://www.mercadolibre.com.ar" />
      <link rel="alternate" hrefLang="es-BO" href="https://www.mercadolibre.com.bo" />
      <link rel="alternate" hrefLang="pt-BR" href="https://www.mercadolivre.com.br" />
      <link rel="alternate" hrefLang="es-CL" href="https://www.mercadolibre.cl" />
      <link rel="alternate" hrefLang="es-CO" href="https://www.mercadolibre.com.co" />
      <link rel="alternate" hrefLang="es-CR" href="https://www.mercadolibre.co.cr" />
      <link rel="alternate" hrefLang="es-DO" href="https://www.mercadolibre.com.do" />
      <link rel="alternate" hrefLang="es-EC" href="https://www.mercadolibre.com.ec" />
      <link rel="alternate" hrefLang="es-GT" href="https://www.mercadolibre.com.gt" />
      <link rel="alternate" hrefLang="es-HN" href="https://www.mercadolibre.com.hn" />
      <link rel="alternate" hrefLang="es-MX" href="https://www.mercadolibre.com.mx" />
      <link rel="alternate" hrefLang="es-NI" href="https://www.mercadolibre.com.ni" />
      <link rel="alternate" hrefLang="es-PA" href="https://www.mercadolibre.com.pa" />
      <link rel="alternate" hrefLang="es-PY" href="https://www.mercadolibre.com.py" />
      <link rel="alternate" hrefLang="es-PE" href="https://www.mercadolibre.com.pe" />
      <link rel="alternate" hrefLang="es-SV" href="https://www.mercadolibre.com.sv" />
      <link rel="alternate" hrefLang="es-UY" href="https://www.mercadolibre.com.uy" />
      <link rel="alternate" hrefLang="es-VE" href="https://www.mercadolibre.com.ve" />
      <link rel="alternate" hrefLang="x-default" href="https://www.mercadolibre.com" />
      {preloadRecommendationsImgs && preloadRecommendationsImgs.map(url => (
        <link
          key={url}
          rel="preload"
          href={url}
          as="image"
        />
      ))}
    </Head>
  );
}

Header.propTypes = {
  description: string.isRequired,
  googleVerification: string,
  title: string.isRequired,
  canonical: string.isRequired,
  preloadRecommendationsImgs: array,
};

export default Header;
