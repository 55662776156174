/* global freya: false */
import { string } from 'prop-types';

function MeliMessages({ siteId, mainDomain }) {
  if (globalThis.freya && freya.messages) {
    // Deremate Messages
    freya.messages.init({
      url: `http://www.mercadolibre.com/org-img/MOVEDR2/${globalThis.location.hash.substring(11)}_${siteId}.json`,
      hash: '#MOVE_FROM',
      callback: 'moveDRCallback',
    });

    // Registration Messages
    // Set url in server
    freya.messages.init({
      url: `https://registration.${mainDomain}/registration/welcome/message`,
      hash: '#WM',
    });

    // Set url in server
    freya.messages.init({
      url: `https://registration.${mainDomain}/registration/confirmation/message`,
      hash: '#CM',
    });

    // Registration Messages from Referral Program
    // Set url in server
    freya.messages.init({
      url: `https://referral.${mainDomain}/welcome/registered`,
      hash: '#WRPM',
    });

    // Association Messages from Referral Program
    // Set url in server
    freya.messages.init({
      url: `https://referral.${mainDomain}/welcome/associated`,
      hash: '#WAPM',
    });
  }

  return null;
}

MeliMessages.propTypes = {
  siteId: string,
  mainDomain: string,
};

export default MeliMessages;
