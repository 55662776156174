import React from 'react';
import { number, array, string, bool } from 'prop-types';
import { Script } from 'nordic/script';

const Hotjar = ({ id = undefined, tagPrefix = '', deviceType, enabled = true, tags = [], nonce, ...rest }) => {
  let defaultId;
  let defaultTagPrefix;

  if (rest.default) { // TODO revisar esto
    const {
      id: defaultIdTemp,
      tagPrefix: defaultTagPrefixTemp } = rest.default;

    defaultId = defaultIdTemp;
    defaultTagPrefix = defaultTagPrefixTemp;
  }

  const hotjarConfig = {
    id: id || defaultId,
    tags: tags || [],
    tagPrefix: tagPrefix || defaultTagPrefix,
    enabled: enabled !== undefined ? enabled : true,
  };

  if (!hotjarConfig.id || !hotjarConfig.enabled) {
    return null;
  }

  const parseTags = () => JSON.stringify(hotjarConfig.tags.map(tag => hotjarConfig.tagPrefix + tag));

  return deviceType === 'desktop' && (
    <Script>
      {`
        (function(h,o,t,j,a,r){
          h.hj= h.hj || function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hotjarConfig.id},hjsv:5};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          r.nonce='${nonce}';
          a.appendChild(r);
        })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');
        ${hotjarConfig.tags.length ? `hj('tagRecording', ${parseTags()});` : ''}
      `}
    </Script>
  );
};

Hotjar.propTypes = {
  id: number,
  tags: array,
  tagPrefix: string,
  enabled: bool,
  nonce: string,
  deviceType: string,
};

export default Hotjar;
